







































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class GoalForm extends Vue {
  public goal = {
    id: 0,
    name: '',
    goalLimit: '',
    actualLimit: 0,
    paymentDescription: '',
    description: '',
    returnUrl: '',
    dateAdd: ''

  };
  public settings: Settings;
  public id: number;


  public constructor() {
    super();
    this.settings = new Settings();
    this.id = +this.$route.params.id;
  }

  public addGoal() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (this.goal.id > 0) {
      this.axios.put(
          this.settings.settings.host + '/api/goals/' + this.goal.id,
          {
            name: this.goal.name,
            goalLimit: +this.goal.goalLimit,
            paymentDescription: this.goal.paymentDescription,
            description: this.goal.description,
            returnUrl: this.goal.returnUrl
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Cel został edytowany'
        });
      });
    } else {
      this.axios.post(
          this.settings.settings.host + '/api/goals',
          {
            name: this.goal.name,
            goalLimit: +this.goal.goalLimit,
            paymentDescription: this.goal.paymentDescription,
            description: this.goal.description,
            returnUrl: this.goal.returnUrl
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Cel został dodany'
        });
        that.$router.push('/panel/goal/list');
      });
    }
  }

  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/goals/' + this.$route.params.id, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.goal = response.data;
    });
  }

  created(): void {
    if (this.id > 0) {
      this.loadData();
    }
  }
}
